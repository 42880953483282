/* colors */
:root {
  --gray: #D9D9D9;

  --white: hsla(0, 0%, 100%, 1);
  --white-95: hsla(0, 0%, 95%, 1);
  --white-90: hsla(0, 0%, 90%, 1);
  --white-80: hsla(0, 0%, 80%, 1);
  --white-70: hsla(0, 0%, 70%, 1);
  --white-62: hsla(0,0%, 62%, 1);
  --white-50: hsla(0, 0%, 50%, 1);
  --white-40: hsla(0, 0%, 40%, 1);
  --white-30: hsla(0, 0%, 30%, 1);
  --white-20: hsla(0, 0%, 20%, 1);
  --black: hsla(0, 0%, 0%, 1);

  --aqua: hsla(170, 49%, 69%, 1);
  --aqua-89: hsla(170, 49%, 89%, 1);
  --aqua-79: hsla(170, 49%, 79%, 1);
  --aqua-59: hsla(170, 49%, 59%, 1);
  --aqua-49: hsla(170, 49%, 49%, 1);
  --aqua-39: hsla(170, 49%, 39%, 1);
  --aqua-29: hsla(170, 49%, 29%, 1);

  --orange: hsla(15, 98%, 67%, 1);
  --orange-97: hsla(15, 98%, 97%, 1);
  --orange-87: hsla(15, 98%, 87%, 1);
  --orange-57: hsla(15, 98%, 57%, 1);
  --orange-37: hsla(15, 98%, 37%, 1);

  --purple: hsla(282, 36%, 76%, 1);
  --purple-66: hsla(282, 36%, 66%, 1);
  --purple-16: hsla(282, 36%, 16%, 1);

  --teal: hsla(183, 93%, 32%, 1);
  --teal-42: hsla(183, 93%, 42%, 1);
  --teal-22: hsla(183, 93%, 22%, 1);

  --green: hsla(75, 47%, 58%, 1);
  --green-68: hsla(75, 47%, 68%, 1);
  --green-48: hsla(75, 47%, 48%, 1);
  --green-36: hsla(75, 47%, 36%, 1);
  --green-18: hsla(75, 47%, 18%, 1);

  --yellow: hsla(36, 92%, 71%, 1);
  --yellow-71: hsla(36, 92%, 71%, 1);
  --yellow-31: hsla(36, 92%, 31%, 1);

  --red: hsla(8, 73%, 58%, 1);
  --red-78: hsla(8, 73%, 78%, 1);
  --red-68: hsla(8, 73%, 68%, 1);
  --red-38: hsla(8, 73%, 38%, 1);
  --red-28: hsla(8, 73%, 28%, 1);
}

/* everything else */
:root {
  --border-radius: 6px;
  --border: 2px solid var(--white-50);
  --border-highlited: 2px solid var(--aqua);
  --theme-color-alert: var(--orange);
  --global-background: var(--white-95);
  --header-height: 60px;
  --toastify-color-progress-light: var(--aqua-49);
  --box-shadow: -4px 4px 0px rgba(0, 0, 0, 0.15);
  --box-shadow-hover: -1px 1px 0px rgba(0, 0, 0, 0.15);
  --box-shadow-selected-hover: -0px 0px 1px rgba(0, 0, 0, 0.15);
}

.Popover-body {
  display: flex;
  justify-content: flex-end;
}

.Popover-tip {
  opacity: 0;
}

@font-face {
  font-family: "OpenSans-Light";
  src: local('OpenSans-Light'), url("../fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "OpenSans-SemiBold";
  src: local('OpenSans-SemiBold'), url("../fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

:root {
  --light-font: OpenSans-Light, sans-serif;
  --semibold-font: OpenSans-SemiBold, sans-serif;
}

* {
  font-family: var(--light-font);
}
